
    import PortfolioService from "@/service/PortfolioService";
    import Alert from "@/components/Alert";
    import AuthService, { AuthError } from '@/service/AuthService'
    import { backendExceptionHandler, authStateHandler } from "@/mixins";
    import { getAuth, onAuthStateChanged } from "firebase/auth";
    import firebaseApp from "@/firebaseApp"
    import Page from '@/components/Page'
    import _ from 'lodash'

    const auth = getAuth(firebaseApp);

    const PING_INTERVAL_MS = 5000  // milliseconds

    export default {
        name: 'App',
        mixins: [ backendExceptionHandler, authStateHandler ],
        components: { Alert, Page },
        data() {
            return {
                errorMessage: null,
                portfolios: [],
                overlay: false,
                networkError: false,
                componentKey: true,
                authInitialized: false || process.server,
            }
        },
        computed: {
            isLoggedIn() {
                return this.$store.state.account.user
            },
            isDarkMode() {
                return this.$store.state.theme.isDarkMode
            },
        },
        methods: {

            async updatePortfolios() {
                try {
                    this.portfolios = await this.portfolioService.queryAll(
                        this.$store.state.preferences.activeChartInterval,
                    )
                    this.$root.$emit('send-portfolios', this.portfolios)
                    this.errorMessage = ''
                } catch(e) {
                    this.$root.$emit('send-portfolios', [])
                    if (e instanceof AuthError) {
                        return
                    }
                    this.handleBackendException(e, this.$t('layouts.default.portfoliosLoadError'))
                }
            },

            cancelAutoUpdate () {
                clearInterval(this.timer);
            },

            async ping(retry = false) {
                if (this.networkError && !retry){
                    return  // we are already monitoring the connection
                }
                this.networkError = true
                this.overlay = true
                try {
                    await AuthService.ping(this.$axios)
                    this.networkError = false
                    this.timeout = setTimeout(() => this.overlay = false, 2000)
                } catch(e) {
                    console.log(e)
                    this.timeout = setTimeout(this.ping, PING_INTERVAL_MS, true)
                }
            },
            fetchAccountCurrency() {
                this.portfolioService.getAccount().then(account => {
                    this.$store.commit(
                        'account/setSubscribedProductIds',
                        account.subscribedProductIds)
                    this.$store.commit(
                        'account/setIsSubscriber', account.isSubscriber)
                    this.$store.commit('account/setCurrency', account.currency)
                    this.$store.commit('account/setShowNewUsersTour', account.showNewUsersTour)
                    
                    const langQuery = this.$route.query.lang;
                    const languageCode = this.$getAvailableLocales().map(l => l.code).includes(langQuery) ? langQuery : null;
                    if (!!languageCode && languageCode !== account.language) {
                        this.$setLocale(languageCode);
                        AuthService.updateUserLanguage(languageCode, this.$axios, this.$t);
                    } else {
                        this.setAccountLanguage(account)
                    }
                }).catch(e => this.handleBackendException(
                    e, this.$t('layouts.default.accountCurrencyFetchError'))
                )
            },
            setAccountLanguage(account) {
                const locale = account.language;
                this.$vuetify.lang.current = locale;
                this.$setLocale(locale);
            },
            accountCurrencyChanged(newCurrency) {
                this.$store.commit('account/setCurrency', newCurrency)
            },
            updateErrorMessage() {
                this.errorMessage = '';
            }
        },

        watch: {
            isLoggedIn(user) {
                if (!user) {
                    return
                }
                this.fetchAccountCurrency()
            },
            isDarkMode: {
                immediate: true,
                handler(newState) {
                    this.$vuetify.theme.dark = newState;
                }
            }
        },
        created() {
            this.portfolioService = new PortfolioService(this.$axios)
            this.unsubscribe = onAuthStateChanged(auth, this.authStateChanged)
            if (!this.$store.state.account.user?.preventLogout) {
                // workaround for e2e testing until we use Firebase emulator
                this.$store.commit('account/logout')
            }
            if (!this.$store.state.preferences.activeChartInterval) {
                this.$store.commit(
                    'preferences/activeChartInterval',
                    'fiveyears',
                )
            }
            // store dividend year select
            if (!this.$store.state.preferences.dividendYearSelected) {
                const currentYear = new Date().getFullYear();
                this.$store.commit(
                    'preferences/setDividendYearSelected',
                    `${currentYear}`,
                )
            }
        },

        beforeMount() {
            this.$root.$on(
                'request-portfolios-refresh',
                _.debounce(this.updatePortfolios, 3000, {
                    'leading': true,
                }),
            )
            this.$root.$on('network-error', this.ping)
            this.$root.$on('account-currency-changed', this.accountCurrencyChanged);
        },

        beforeDestroy() {
            clearTimeout(this.timeout)
            this.unsubscribe()
            this.$root.$off('request-portfolios-refresh')
            this.$root.$off('network-error', this.ping)
            this.$root.$off('account-currency-changed', this.accountCurrencyChanged)
        }
    };
